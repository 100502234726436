.header {
  background-color: #141414;
  height: 70px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo,
.navbar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  img {
    height: 50px;
  }

  .backToMainBtn {
    color: #ffffff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: brightness(130%) !important;
    }

    &:active {
      color: #007f96 !important;
    }
  }

  .settings {
    display: flex;
    align-items: center;
    height: 100%;

    > div {
      margin-right: 12px;
    }
  }

  .verticalLine {
    width: 1px;
    height: 50%;
    background-color: gray;
    margin: 0px 5px;
  }
}

.settingsBtn {
  color: #0097b2 !important;
  margin: 0px 10px 0px 10px;

  &:hover {
    filter: brightness(130%) !important;
  }

  &:active {
    color: #007f96 !important;
  }
}

.burgerColor {
  background-color: #f4f4f4 !important;
}

.burgerBtn {
  background-color: #141414;
  border: none;
  font-size: 1.6rem;
  width: 10px;

  &:active {
    background-color: #141414 !important;
  }
}

.language,
.dropdownItem {
  font-size: 1.2rem;
}

.menuButton {
  padding: 0;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #0097b2;
}

.burgerSettings {
  margin-right: 8px;
  font-size: 16px;
  color: rgb(0, 0, 0) !important;
}

.lngIcons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .logo img,
  .navigation img {
    height: 40px;
  }
}

li {
  background-color: transparent !important;
}
