.scrollBtn {
  position: fixed;
  background-color: #282828;
  color: #ffffff;
  border: 2px solid #444;
  z-index: 99;
  right: 3%;
  bottom: 3%;
  transition: 0.6s all;

  &:hover {
    background-color: #333 !important;
    box-shadow: 0px 0px 5px #555 !important;
  }

  &:active {
    background-color: #555 !important;
  }
}

.hidden {
  opacity: 0;
}
